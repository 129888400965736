<template>
  <div class="map-container">
    <div class="d-flex flex-row justify-between">
      <p class="sub-text">Da click en el mapa, para asignar la ubicación</p>
      <button type="button" class="mb-2 btn btn-sm btn-outline-dark" @click="getUserLocation"><em
          class="icon ni ni-location mr-1"></em> Obtener Ubicación Actual
      </button>
    </div>
    <GMapMap
        class="map-component"
        :center="center"
        :zoom="zoom"
        :options="MAP_OPTIONS"
        @click="updateMarker"
    >
      <GMapMarker :position="model" />
    </GMapMap>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, toRefs, watch} from 'vue';
import Swal from "sweetalert2";

const MAP_OPTIONS = {
  styles: [{
      featureType: "poi",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
  }]
};

export default defineComponent({
  props: ['modelValue','center'],
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const state = reactive({
      zoom: 22,
      loaded: false,
      center: props.center || {lat: 21.8607804, lng: -102.2662378}
    });

    const model = computed({
      get(){
        return props.modelValue || {lat: 21.8607804, lng: -102.2662378};
      },
      set(value){
        emit('update:modelValue', value);
      }
    });

    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          // Actualizar la posición del marcador al obtener la ubicación del usuario
          const new_position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          model.value = new_position;
          state.center = new_position;
          state.zoom = 22;
        });
      } else {
        Swal.fire('Error', 'Geolocalización no está disponible en tu navegador.', 'error');
      }
    };

    const updateMarker = (event) => {
      // Actualizar la posición del marcador al hacer clic en el mapa
      model.value = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    };

    watch(() => props.center, (value) => {
      if(state.loaded) return false;
      state.loaded = true;
      state.center = value;
    });

    onMounted(() => {
      if(props.modelValue.lat == null && props.modelValue.lng == null){
        getUserLocation();
      }
    });

    return {
      ...toRefs(state),
      model,
      getUserLocation,
      updateMarker,
      MAP_OPTIONS
    };
  },
});
</script>

<style lang="scss">
.map-container {
  .map-component {
    width: 100%;
    height: 450px;
  }
}
</style>
